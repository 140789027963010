import React, { useContext } from "react"

import MedicineBox from "elements/MedicineBox"
import Search from "../../../../Search/Search"
import AddMoreMedicinesButton from "./AddMoreMedicinesButton"

import { AppContext } from "../../../../../context/AppContext"

const AddMedicinesSection = ({
  showSearch,
  setShowSearch,
  searchComponentProps,
}) => {
  const { state } = useContext(AppContext)

  switch (true) {
    case !!showSearch:
      return (
        <MedicineBox
          handleDelete={
            state?.updatedPrescription?.cancelledMedicines?.length === 0
              ? null
              : () => setShowSearch(false)
          }
        >
          <Search
            searchType="drug"
            label="Search Medicines"
            {...searchComponentProps}
          />
        </MedicineBox>
      )
    case !showSearch &&
      state?.updatedPrescription?.cancelledMedicines?.length > 0:
      return (
        <AddMoreMedicinesButton
          handleClick={() => {
            setShowSearch(true)
          }}
        />
      )
    default:
      return null
  }
}

export default AddMedicinesSection
